<template>
  <div>
    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="userData.avatar"
          :text="userData.wechatnick"
          :variant="`light`"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-1">
        {{ userData.wechatnick }}
      </h4>
    </b-media>

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>
        <!-- Field: Username -->
        <b-col cols="12" md="4">
          <b-form-group label="微信id" label-for="wechatid">
            <b-form-input id="wechatid" v-model="userData.wechatid" />
          </b-form-group>
        </b-col>

        <!-- Field: Full Name -->
        <b-col cols="12" md="4">
          <b-form-group label="微信帐号" label-for="wechatno">
            <b-form-input id="wechatno" v-model="userData.wechatno" />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col cols="12" md="4">
          <b-form-group label="设备序号" label-for="deviceid">
            <b-form-input id="deviceid" v-model="userData.deviceid" />
          </b-form-group>
        </b-col>

        <!-- Field: Status -->
        <b-col cols="12" md="4">
          <b-form-group label="设备标识" label-for="module">
            <b-form-input id="module" v-model="userData.module" />
          </b-form-group>
        </b-col>

        <!-- Field: Role -->
        <b-col cols="12" md="4">
          <b-form-group label="部门名称" label-for="dname">
            <v-select
              v-model="roleSelect"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="roleOptions"
              :reduce="(val) => val.value"
              :clearable="false"
              input-id="user-dname"
              @input="changeAcc"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col cols="12" md="4">
          <b-form-group label="客服名称" label-for="nickname">
            <v-select
              v-model="accountSelect"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="accountOptions"
              :reduce="(val) => val.label"
              :clearable="true"
              input-id="user-nickname"
              @input="indexSelect"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="save()"
    >
      保 存
    </b-button>

    <b-button
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      重 置
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
} from "bootstrap-vue";

import vSelect from "vue-select";

import useJwt from "@/auth/jwt/useJwt";

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },

    module: "",
  },
  methods: {
    save() {
  
      this.$swal({
        title: "确定要保存吗?",
        text: "保存设备信息!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        useJwt
          .updateDevice({
            id: this.userData.id,
            module: this.userData.module,
            deviceid: this.userData.deviceid,
            devnickname: this.userData.devnickname,
            accountid: this.accountid,
            did: this.did,
          })
          .then((res) => {
            console.log("res.data" + JSON.stringify(res.data));
            if (res.data.code == 0) {
              this.$swal({
                icon: "success",
                title: "保存!",
                text: "保存成功",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            } else {
              this.$swal({
                icon: "error",
                title: "保存失败!",
                text: "请联系管理员处理",
                customClass: {
                  confirmButton: "btn btn-error",
                },
              });
            }
          });
      });
    },
    indexSelect(value) {
      let obj = {};
      obj = this.accountOptions.find((item) => {
        return item.label === value;
      });
      if (obj != null) {
        this.accountid = obj.value;
      }
    },
    changeAcc(newVal) {
      this.accountOptions = [];
      this.userData.nickname = "";
      this.did = newVal;
      useJwt
        .getAllAccountInfoByTypeCidDid({ cid: this.userData.cid, did: newVal })
        .then((response) => {
          const forArr = response.data.data;
          forArr.forEach((item, i) => {
            this.accountOptions.push({ label: item.nickname, value: item.id });
          });
          this.accountSelect = 0;
        });
    },
  },

  data() {
    return {
      roleSelect: null,
      roleOptions: [],
      accountSelect: null,
      accountOptions: [],
      accountid: "",
    };
  },

  mounted() {
    useJwt.queryByCid({ cid: this.userData.cid }).then((response) => {
      if (response.data.code == 0) {
        const forArr = response.data.data;
        console.log("obj" + JSON.stringify(forArr));
        forArr.forEach((item, i) => {
          this.roleOptions.push({ label: item.name, value: item.id });
        });
        this.roleSelect = this.userData.dname;
        this.did = this.userData.did;
        this.accountid = this.userData.accountid;
      }
    }),
      useJwt
        .getAllAccountInfoByTypeCidDid({
          cid: this.userData.cid,
          did: this.userData.did,
        })
        .then((response) => {
          const forArr = response.data.data;
          forArr.forEach((item, i) => {
            this.accountOptions.push({ label: item.nickname, value: item.id });
          });
          this.accountSelect = this.userData.nickname;
        });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
